/* eslint-disable react-hooks/rules-of-hooks */
import { graphql, useStaticQuery } from 'gatsby';

const logoCollectionTop = () => {
  const {
    windCores: {
      childImageSharp: { fixed: windCores },
    },
    techbuyer: {
      childImageSharp: { fixed: techbuyer },
    },
    prior1: {
      childImageSharp: { fixed: prior1 },
    },
    fzi: {
      childImageSharp: { fixed: fzi },
    },
    axel: {
      childImageSharp: { fixed: axel },
    },
  } = useStaticQuery(graphql`
    query {
      windCores: file(relativePath: { eq: "pages/home/support/wind-сores.png" }) {
        childImageSharp {
          fixed(height: 89) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      techbuyer: file(relativePath: { eq: "pages/home/support/techbuyer.png" }) {
        childImageSharp {
          fixed(height: 52) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      prior1: file(relativePath: { eq: "pages/home/support/prior1.png" }) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      fzi: file(relativePath: { eq: "pages/home/support/fzi.png" }) {
        childImageSharp {
          fixed(height: 83) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      axel: file(relativePath: { eq: "pages/home/support/axel.png" }) {
        childImageSharp {
          fixed(height: 49) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);

  return [
    {
      image: windCores,
      path: 'https://www.windcores.de/',
    },
    {
      image: techbuyer,
      path: 'https://www.techbuyer.com/de/',
    },
    {
      image: prior1,
      path: 'https://prior1.com/',
    },
    {
      image: fzi,
      path: null,
    },
    {
      image: axel,
      path: 'https://www.axel.energy/de/index.php',
    },
  ];
};

export default logoCollectionTop;
