import classNames from 'classnames/bind';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import Heading from 'components/shared/heading';
import useLottie from 'hooks/use-lottie';

import Advantages from './advantages';
import animationData from './data/animation.json';
import Features from './features';
import styles from './hero.module.scss';

const cx = classNames.bind(styles);

const Hero = ({ title, advantages, features }) => {
  const {
    illustration: {
      childImageSharp: { fluid: illustration },
    },
  } = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "pages/home/hero/illustration.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1020) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  const [animationVisibilityRef, inView] = useInView();
  const [isAnimationReady, setIsAnimationReady] = useState(false);
  const [animation, animationRef] = useLottie(
    {
      renderer: 'svg',
      loop: true,
      autoplay: false,
      animationData,
    },
    {
      loaded_images() {
        setIsAnimationReady(true);
      },
    }
  );

  useEffect(() => {
    if (!isAnimationReady) {
      return;
    }

    if (inView) {
      animation.play();
    } else {
      animation.pause();
    }
  }, [animation, isAnimationReady, inView]);

  return (
    <section className={cx('wrapper')} ref={animationVisibilityRef}>
      <div className="container">
        <Heading
          className={cx('title')}
          highlightedWordsWithoutWrap={false}
          color="secondary"
          size="xl"
          tag="h1"
          innerHTML={title}
        />

        <div className={cx('content')}>
          <div className={cx('media-wrapper')}>
            <div
              className={cx('animation', {
                animate: isAnimationReady,
              })}
              ref={animationRef}
            >
              <div className={cx('placeholder')}>
                <Img fluid={illustration} alt="" fadeIn={false} loading="eager" />
              </div>
            </div>
          </div>
          <Advantages {...advantages} />
        </div>

        <Features items={features} />
      </div>
    </section>
  );
};

Hero.defaultProps = {
  title: `The platform for <span><div>green</div></span> and<br> distributed cloud computing`,
  advantages: {
    title: 'Benefits',
    features: [
      {
        text: 'We offer truly sustainable cloud computing services powered by local renewable energy sources like wind and solar.',
      },
      {
        text: 'Full data souvereignity and GDPR compliance at german data center sites of your choice - secure and protected by the swarm.',
      },
      {
        text: 'Fast edge cloud services that are close to our customers for innovative companies with critical and data-intensive applications.',
      },
    ],
    newsletter: 'Save a spot for latest updates',
  },
  features: [
    {
      iconName: 'kubernetesCluster',
      title: 'Managed Kubernetes Cluster',
      description: `Microservices and containers are your playground? We've got you covered with our Managed Kubernetes Cluster. Start deploying your applications in no time without the hassle of administrating Kubernetes on your own.`,
    },
    {
      iconName: 'serverless',
      title: 'Serverless and<br />FaaS',
      description: `You are developing software and you want to get rid of everything beyond your code? With FaaS we strip infrastructure related tasks from your workflow and offer you a frictionless and intuitive cloud environment. Just you, your code and your coffee.`,
    },
    {
      iconName: 'computing',
      title: `Edge-Cloud<br />Computing`,
      description: `Your data is not a fan of lenghty roundtrips? Our distributed edge data centers may be just around the corner to compute your most precious bits. Low latency and full data souvereignity will accelerate your data and your business.`,
    },
  ],
};

Hero.propTypes = {
  title: PropTypes.string,
  advantages: PropTypes.objectOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      features: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ),
  features: PropTypes.arrayOf(
    PropTypes.shape({
      iconName: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
};

export default Hero;
