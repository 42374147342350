import { useEffect, useState, useRef, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';

import { isSafari } from 'utils/check-browser';

const initialIntervals = [4000, 4000, 6000];
const intervals = [5320, 4000, 6000];
const allowedReadyState = 3;

const useSynchronizedAnimation = () => {
  const [blockVisibilityRef, inView] = useInView({
    threshold: 0.2,
  });
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [isTabActive, setIsTabActive] = useState(true);
  const [activeIndex, setActiveIndex] = useState();
  const [addAnimationDelay, setAddAnimationDelay] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const videoRef = useRef(null);
  const timeoutId = useRef(null);

  // Safari does not fire canplay video event
  // https://stackoverflow.com/questions/50051639/javascript-html5-video-event-canplay-not-firing-on-safari
  const canPlayEventName = isSafari ? 'loadedmetadata' : 'canplay';
  const video = videoRef.current;

  const play = useCallback(() => {
    setActiveIndex(0);
    video.play();
    setIsPlaying(true);
  }, [video]);

  const pause = useCallback(() => {
    clearTimeout(timeoutId.current);
    timeoutId.current = null;
    video.pause();
    video.currentTime = 0;
    setIsPlaying(false);
    setAddAnimationDelay(false);
    setActiveIndex(null);
  }, [video]);

  const nextIndex = () => {
    setActiveIndex((currentIndex) => (currentIndex === 2 ? 0 : currentIndex + 1));
  };

  const switchIndex = useCallback((interval) => {
    clearTimeout(timeoutId);
    timeoutId.current = null;
    timeoutId.current = setTimeout(nextIndex, interval);
  }, []);

  const setVideoReady = useCallback(() => {
    setIsVideoReady(true);
  }, []);

  const handleVisibilityChange = useCallback(() => {
    if (document.hidden && isTabActive) {
      setIsTabActive(false);
    } else {
      setIsTabActive(true);
    }
  }, [isTabActive]);

  useEffect(() => {
    if (!addAnimationDelay && activeIndex === 1) {
      setAddAnimationDelay(true);
    }
  }, [activeIndex, addAnimationDelay]);

  useEffect(() => {
    if (isPlaying) {
      const interval = addAnimationDelay ? intervals[activeIndex] : initialIntervals[activeIndex];
      switchIndex(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex, isPlaying, switchIndex]);

  useEffect(() => {
    if (!isPlaying && inView && isTabActive && isVideoReady) play();
    else if ((isPlaying && !inView) || (isPlaying && !isTabActive)) pause();
  }, [inView, isPlaying, isTabActive, isVideoReady, pause, play]);

  useEffect(() => {
    if (video) {
      if (video.readyState >= allowedReadyState) {
        setIsVideoReady(true);
      } else {
        video.addEventListener(canPlayEventName, setVideoReady);
      }
    }
    return () => {
      if (video) {
        video.removeEventListener(canPlayEventName, setVideoReady);
      }
    };
  }, [canPlayEventName, setVideoReady, video]);

  useEffect(() => {
    if (document) {
      document.addEventListener('visibilitychange', handleVisibilityChange);
    }
    return () => {
      if (document) {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      }
    };
  }, [handleVisibilityChange]);

  return [activeIndex, addAnimationDelay, videoRef, blockVisibilityRef];
};

export default useSynchronizedAnimation;
