import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import Heading from 'components/shared/heading';
import useLottie from 'hooks/use-lottie';
import IconCheck from 'icons/check.inline.svg';

import animationData from './data/animation.json';
import styles from './special.module.scss';

const cx = classNames.bind(styles);

const Special = ({ title, description, features }) => {
  const [animationVisibilityRef, inView, entry] = useInView({
    threshold: 0.4,
  });
  const [isAnimationReady, setIsAnimationReady] = useState(false);
  const [isAnimationStarted, setIsAnimationStarted] = useState(false);

  const [animation, animationRef] = useLottie(
    {
      renderer: 'svg',
      loop: true,
      autoplay: false,
      animationData,
    },
    {
      loaded_images() {
        setIsAnimationReady(true);
      },
    }
  );

  useEffect(() => {
    if (!animation || !entry) {
      return;
    }
    if (isAnimationReady && inView) {
      if (isAnimationStarted) {
        animation.play();
      } else {
        setIsAnimationStarted(true);
        animation.playSegments(
          [
            [0, 186],
            [86, 186],
          ],
          true
        );
      }
    } else {
      animation.pause();
    }
  }, [animation, isAnimationReady, inView, isAnimationStarted, entry]);

  return (
    <section className={cx('wrapper')} id="platform">
      <div className={cx('container', 'inner')}>
        <div className={cx('head')}>
          <Heading
            className={cx('title')}
            tag="h2"
            highlightedWordsColor="accent-secondary"
            size="xl"
            innerHTML={title}
          />
          <p className={cx('description')}>{description}</p>
        </div>

        <div className={cx('content')}>
          <div className={cx('animation-wrapper')} ref={animationVisibilityRef}>
            <div className={cx('aspect-ratio-box')}>
              <div className={cx('animation')} ref={animationRef} />
            </div>
          </div>

          <div className={cx('features')}>
            <Heading className={cx('features-title')} size="lg" tag="h3">
              {features.title}
            </Heading>
            <ul>
              {features.items.map(({ text }, index) => (
                <li key={index}>
                  <IconCheck />
                  <span dangerouslySetInnerHTML={{ __html: text }} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

Special.defaultProps = {
  title: `What makes us special?`,
  description: 'Our smart orchestration platform unlocks the power of many.',
  features: {
    title: 'Crucial Points',
    items: [
      {
        text: `The decentralized platform enables a self organizing and resilient system.`,
      },
      {
        text: 'An integrated energy management system allows for cross-location optimization.',
      },
      {
        text: 'Without even noticing, your company is an active part of the green energy transition!',
      },
    ],
  },
};

Special.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  features: PropTypes.objectOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ),
};

export default Special;
