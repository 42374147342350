/* eslint-disable react-hooks/rules-of-hooks */
import { graphql, useStaticQuery } from 'gatsby';

const logoCollectionMiddle = () => {
  const {
    innoWerft: {
      childImageSharp: { fixed: innoWerft },
    },
    exist: {
      childImageSharp: { fixed: exist },
    },
    startupBw: {
      childImageSharp: { fixed: startupBw },
    },
    netempire: {
      childImageSharp: { fixed: netempire },
    },
  } = useStaticQuery(graphql`
    query {
      innoWerft: file(relativePath: { eq: "pages/home/support/inno-werft.png" }) {
        childImageSharp {
          fixed(height: 60) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      exist: file(relativePath: { eq: "pages/home/support/exist.png" }) {
        childImageSharp {
          fixed(height: 48) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      startupBw: file(relativePath: { eq: "pages/home/support/startup-bw.png" }) {
        childImageSharp {
          fixed(height: 66) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      netempire: file(relativePath: { eq: "pages/home/support/netempire.png" }) {
        childImageSharp {
          fixed(height: 71) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);

  return [
    {
      image: innoWerft,
      path: 'https://innowerft.com/',
    },
    {
      image: exist,
      path: null,
    },
    {
      image: startupBw,
      path: null,
    },
    {
      image: netempire,
      path: 'https://netempire.ag/',
    },
  ];
};

export default logoCollectionMiddle;
