import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';

import decentralizedData from './data/decentralized.json';
import resilientData from './data/resilient.json';
import sustainableData from './data/sustainable.json';
import Feature from './feature';
import styles from './features.module.scss';

const animations = {
  decentralized: decentralizedData,
  resilient: resilientData,
  sustainable: sustainableData,
};

const cx = classNames.bind(styles);

const Features = ({ items }) => {
  const [sectionRef, inView] = useInView();

  return (
    <section className={cx('wrapper')} ref={sectionRef}>
      <div className={cx('inner')}>
        <div className={cx('items-wrapper')}>
          {items.map(({ title, description, animation }, index) => (
            <Feature
              title={title}
              description={description}
              animationData={animations[animation]}
              key={index}
              inView={inView}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

Features.defaultProps = {
  items: [
    {
      title: 'Sustainable',
      animation: 'sustainable',
      description:
        'We utilize existing infrastructure of wind and solar parks to operate our edge data centers. By direct feed of local and cheap renewable energy we enable strong synergies and high efficiency. No greenwashing. The real deal.',
    },
    {
      title: 'Decentralized',
      animation: 'decentralized',
      description:
        'Local edge cloud services for innovative companies with latency-sensitive and critical IT workloads. Our decentralized edge data centers are close to our customers and even closer to the green energy sources.',
    },
    {
      title: 'Resilient',
      animation: 'resilient',
      description:
        'Our smart swarm of distributed data centers enables geo redundancy, failover scenarios, dynamic workload migration and encrypted data. Automated, secure and robust in a data center cluster of your choice.',
    },
  ],
};

Features.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
};

export default Features;
