import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from 'components/shared/button';
import Heading from 'components/shared/heading';
import Input from 'components/shared/input';
import IconCheck from 'icons/check.inline.svg';
import Checkmark from 'icons/checkmark.inline.svg';
import sendSubscribeFormData from 'utils/send-subscribe-form-data';

import styles from './advantages.module.scss';
import Arrow from './images/arrow.inline.svg';

const cx = classNames.bind(styles);

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email('Must be a valid email')
    .required('Business email is a required field'),
});

const Advantages = ({ title, features, newsletter }) => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [serverResponse, setServerResponse] = useState(null);

  const onSubmit = (values) => {
    try {
      sendSubscribeFormData({
        email: values.email,
      }).then(() => {
        setIsSubmitted(true);
      });
    } catch (error) {
      setServerResponse('error');
    }
  };

  const handleInputChange = () => {
    if (isSubmitted) {
      setIsSubmitted(false);
    }
  };

  return (
    <div className={cx('wrapper')} id="benefits">
      <Heading className={cx('title')} color="secondary" size="lg" tag="h3">
        {title}
      </Heading>
      <ul>
        {features.map(({ text }, index) => (
          <li key={index}>
            <IconCheck />
            {text}
          </li>
        ))}
      </ul>

      <form className={cx('form')} noValidate onSubmit={handleSubmit(onSubmit)}>
        <span className={cx('form-title')}>{newsletter}</span>
        <div className={cx('form-inner')}>
          <Input
            name="email"
            placeholder="Business email..."
            autoComplete="email"
            error={errors?.email?.message}
            type="email"
            ref={register}
            onChange={handleInputChange}
          />
          <Button className={cx('button', { checked: isSubmitted })} type="submit">
            {isSubmitted ? (
              <Checkmark />
            ) : (
              <>
                <div>Subscribe</div>
                <Arrow className={cx('arrow')} />
              </>
            )}
          </Button>
          {serverResponse === 'error' && (
            <span className={cx('error')}>
              Something went wrong, please, reload the page and try again
            </span>
          )}
        </div>
      </form>
    </div>
  );
};

Advantages.propTypes = {
  title: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  newsletter: PropTypes.string.isRequired,
};

export default Advantages;
