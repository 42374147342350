import useMediaMatch from '@rooks/use-media-match';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Heading from 'components/shared/heading';

import styles from './application.module.scss';
import ArrowRight from './images/arrow-right.inline.svg';
import useSynchronizedAnimation from './use-synchronized-animation';

const cx = classNames.bind(styles);

const Application = ({ language, title, tabs }) => {
  const isMobile = useMediaMatch('(max-width: 767px)');
  const [activeIndex, addAnimationDelay, videoRef, blockVisibilityRef] = useSynchronizedAnimation();

  const videoSrc = `/videos/application-${language}.mp4`;
  return (
    <section className={cx('wrapper')} ref={blockVisibilityRef} id="features">
      <div className={cx('container', 'inner')}>
        <div className={cx('left')}>
          <Heading className={cx('title')} tag="h2" size="xl">
            {title}
          </Heading>
          {isMobile && (
            <div className={cx('tabs-content')}>
              <video ref={videoRef} src={videoSrc} type="video/mp4" muted loop playsInline />
            </div>
          )}

          <ul className={cx('tabs')} data-position={activeIndex}>
            {tabs.map(({ title }, index) => (
              <li
                className={cx('tab', {
                  active: index === activeIndex,
                  delay: addAnimationDelay,
                })}
                key={index}
              >
                <span>{title}</span>
                <ArrowRight />
              </li>
            ))}
          </ul>
        </div>

        {!isMobile && (
          <div className={cx('tabs-content')}>
            <video ref={videoRef} src={videoSrc} type="video/mp4" muted loop playsInline />
          </div>
        )}
      </div>
    </section>
  );
};

Application.defaultProps = {
  title: 'Simple application management',
  tabs: [
    {
      title: 'Locations and clusters',
    },
    {
      title: 'Energy status and latency',
    },
    {
      title: 'Auto resources allocation',
    },
  ],
  language: 'en',
};

Application.propTypes = {
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ),
  language: PropTypes.oneOf(['en', 'de']),
};

export default Application;
