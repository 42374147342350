import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import Heading from 'components/shared/heading';
import useLottie from 'hooks/use-lottie';

import styles from './feature.module.scss';

const cx = classNames.bind(styles);

const Feature = (props) => {
  const { title, description, animationData, inView } = props;
  const [isAnimationReady, setIsAnimationReady] = useState(false);
  const additionalClassNameAnimation = isAnimationReady ? 'visible' : 'hidden';
  const [animation, animationRef] = useLottie(
    {
      renderer: 'svg',
      loop: true,
      autoplay: false,
      animationData,
    },
    {
      loaded_images() {
        setIsAnimationReady(true);
      },
    }
  );

  useEffect(() => {
    if (!animation) {
      return;
    }
    if (isAnimationReady && inView) {
      animation.play();
    } else {
      animation.pause();
    }
  }, [animation, isAnimationReady, inView]);

  return (
    <div className={cx('wrapper')}>
      <div className={cx('image-wrapper', additionalClassNameAnimation)} ref={animationRef} />
      <Heading className={cx('title')} color="secondary" size="lg" tag="h3">
        {title}
      </Heading>
      <p className={cx('description')}>{description}</p>
    </div>
  );
};

Feature.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  inView: PropTypes.bool.isRequired,
};

export default Feature;
