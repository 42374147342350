export default async function sendSubscribeFormData(values) {
  try {
    const response = await fetch(`${process.env.GATSBY_SUBSCRIBE_URL}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values),
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
}
