/* eslint-disable react-hooks/rules-of-hooks */
import { graphql, useStaticQuery } from 'gatsby';

const logoCollectionBottom = () => {
  const {
    eco: {
      childImageSharp: { fixed: eco },
    },
    euroCloud: {
      childImageSharp: { fixed: euroCloud },
    },
    bsw: {
      childImageSharp: { fixed: bsw },
    },
    fokusEnergie: {
      childImageSharp: { fixed: fokusEnergie },
    },
  } = useStaticQuery(graphql`
    query {
      eco: file(relativePath: { eq: "pages/home/support/eco.png" }) {
        childImageSharp {
          fixed(height: 40) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      euroCloud: file(relativePath: { eq: "pages/home/support/euro-cloud.png" }) {
        childImageSharp {
          fixed(height: 75) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      bsw: file(relativePath: { eq: "pages/home/support/bsw.png" }) {
        childImageSharp {
          fixed(height: 84) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      fokusEnergie: file(relativePath: { eq: "pages/home/support/fokus-energie.png" }) {
        childImageSharp {
          fixed(height: 51) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);

  return [
    {
      image: eco,
      path: 'https://www.eco.de/',
    },
    {
      image: euroCloud,
      path: 'https://www.eurocloud.de/',
    },
    {
      image: bsw,
      path: 'https://www.solarwirtschaft.de/',
    },
    {
      image: fokusEnergie,
      path: 'https://www.fokusenergie.net/de/index.php',
    },
  ];
};

export default logoCollectionBottom;
