import useMediaMatch from '@rooks/use-media-match';
import classNames from 'classnames/bind';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';

import Heading from 'components/shared/heading';
import Link from 'components/shared/link';

import logoCollectionBottom from './logos-collection-bottom';
import logoCollectionMiddle from './logos-collection-middle';
import logoCollectionTop from './logos-collection-top';
import styles from './support.module.scss';

const cx = classNames.bind(styles);

const LogoWrapper = ({ path, children }) => {
  if (path) {
    return (
      <Link className={cx('logo')} to={path} target="_blank">
        {children}
      </Link>
    );
  }
  return <div className={cx('logo')}>{children}</div>;
};

const Support = ({ title, logosTopDescription, logosBottomDescription }) => {
  const isScreenXL = useMediaMatch('(max-width: 1439px)');

  const logosTop = logoCollectionTop();
  const logosMiddle = logoCollectionMiddle();
  const logosBottom = logoCollectionBottom();

  return (
    <section className={cx('wrapper')} id="partners">
      <div className={cx('container', 'inner')}>
        <div className={cx('head')}>
          <Heading
            className={cx('title')}
            highlightedWordsWithoutWrap={false}
            tag="h2"
            size="xl"
            innerHTML={title}
          />
        </div>

        <div className={cx('logos', 'top')}>
          <p className={cx('logos-description')}>{logosTopDescription}</p>
          <div className={cx('logos-inner')}>
            {!isScreenXL
              ? logosTop.map(({ image, path }, index) => (
                  <LogoWrapper path={path} key={index}>
                    <Img fixed={image} alt="" />
                  </LogoWrapper>
                ))
              : logosTop.concat(logosMiddle).map(({ image, path }, index) => (
                  <LogoWrapper path={path} key={index}>
                    <Img fixed={image} alt="" />
                  </LogoWrapper>
                ))}
          </div>
        </div>

        <div className={cx('logos', 'middle')}>
          <div className={cx('logos-inner')}>
            {logosMiddle.map(({ image, path }, index) => (
              <LogoWrapper path={path} key={index}>
                <Img fixed={image} alt="" />
              </LogoWrapper>
            ))}
          </div>
        </div>

        <div className={cx('logos', 'bottom')}>
          <p className={cx('logos-description')}>{logosBottomDescription}</p>
          <div className={cx('logos-inner')}>
            {logosBottom.map(({ image, path }, index) => (
              <LogoWrapper path={path} key={index}>
                <Img fixed={image} alt="" />
              </LogoWrapper>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

Support.defaultProps = {
  title: `Our strong supporters and partners</br> on the path to the green edge cloud`,
  logosTopDescription: 'Partners and Supporters',
  logosBottomDescription: 'Memberships',
};

Support.propTypes = {
  title: PropTypes.string,
  logosTopDescription: PropTypes.string,
  logosBottomDescription: PropTypes.string,
};

export default Support;
