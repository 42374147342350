import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Heading from 'components/shared/heading';

import styles from './features.module.scss';
import Computing from './images/computing.inline.svg';
import KubernetesCluster from './images/kubernetes-cluster.inline.svg';
import Serverless from './images/serverless.inline.svg';
import VirtualMachines from './images/virtual-machines.inline.svg';

const cx = classNames.bind(styles);

const iconsCollection = {
  virtualMachines: VirtualMachines,
  kubernetesCluster: KubernetesCluster,
  computing: Computing,
  serverless: Serverless,
};

const Features = ({ items }) => (
  <div className={cx('wrapper')} id="services">
    {items.map(({ iconName, title, description }, index) => {
      const Icon = iconsCollection[iconName];
      return (
        <div className={cx('item')} key={index}>
          <Icon className={cx(iconName)} />
          <Heading className={cx('title')} size="lg" color="secondary" tag="h4" innerHTML={title} />
          <p className={cx('description')}>{description}</p>
        </div>
      );
    })}
  </div>
);

Features.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      iconName: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Features;
